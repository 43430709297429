import {
  InventoryCenter,
  KeyMCell,
  KeyMCellUpdate,
  MCell,
  MCellCollection,
  MCellCollectionCreateType,
  PlanningDepartment,
  PlanningDepartmentOfInterestUpdate,
  ManufacturingLocationNotificationSystem,
  ManufacturingLocationNotificationSystemInfo,
  WorkCenterProductionLine,
  ManufacturingLocation,
  ProducingLocation,
  InventoryCenterConfigOptions,
  MCellFilter,
  MCellInfo,
  MCellCompletionConfigDto,
  MCellCompletionConfigCreate,
  MCellCompletionConfigUpdate,
} from './types';
import axiosInstance from '../../api';
import { OptionValue } from '../common/EnhancedSelect';

/**
 * API call to get Work Center Production Lines
 */
export const getWorkCenterProductionLines = async (
  manufacturingLocation: ManufacturingLocation,
): Promise<Array<WorkCenterProductionLine>> => {
  const response = await axiosInstance.get(
    '/api/ManufacturingLocation/workCenterProductionLine',
    {
      params: manufacturingLocation,
    },
  );
  return response.data;
};

/**
 * API call to get  a list of Inventory Centers
 * @param param
 */
export const getInventoryCenters = async (param: {
  includeDisabled: boolean;
}): Promise<Array<InventoryCenter>> => {
  const response = await axiosInstance.get<Array<InventoryCenter>>(
    '/api/ManufacturingLocation/inventoryCenter',
    {
      params: { includeDisabled: param.includeDisabled },
    },
  );
  return response.data;
};

/**
 * API call to set an Inventory Center's Configuration Options
 * @param param
 */
export const setInventoryCenterConfigOptions = async (param: {
  inventoryCenterId: string;
  inventoryCenterConfigOptions: InventoryCenterConfigOptions;
}): Promise<InventoryCenter> => {
  const response = await axiosInstance.post<InventoryCenter>(
    `/api/ManufacturingLocation/inventoryCenter/${param.inventoryCenterId}/configOptions`,
    param.inventoryCenterConfigOptions,
  );
  return response.data;
};

/**
 * API call to get all Producing Locations that match the given Inventory Center
 * @param inventoryCenterId
 */
export const getProducingLocations = async (
  inventoryCenterId: string,
): Promise<ProducingLocation[]> => {
  const response = await axiosInstance.get(
    '/api/ManufacturingLocation/producingLocation',
    { params: { inventoryCenterId } },
  );
  return response.data;
};

/**
 * API call to get a list of Planning Departments in an inventory center
 */
export const getPlanningDepartments = async (param: {
  inventoryCenterId?: string;
  departmentsOfInterestOnly?: boolean;
}): Promise<Array<PlanningDepartment>> => {
  const response = await axiosInstance.get<Array<PlanningDepartment>>(
    '/api/ManufacturingLocation/planningDepartment',
    {
      params: {
        inventoryCenterId: param.inventoryCenterId,
        departmentsOfInterestOnly: param.departmentsOfInterestOnly,
      },
    },
  );
  return response.data;
};

/**
 * API call to update a Planning Department
 * @param planningDepartmentUpdate
 */
export const updatePlanningDepartment = async (
  planningDepartmentUpdate: PlanningDepartmentOfInterestUpdate,
): Promise<PlanningDepartment> => {
  const response = await axiosInstance.post(
    `/api/ManufacturingLocation/planningDepartment/${planningDepartmentUpdate.id}/update`,
    { isDepartmentOfInterest: planningDepartmentUpdate.isDepartmentOfInterest },
  );
  return response.data;
};

/**
 * API call to get list of MCells in a manufacturing location
 * @param mCellFilter
 */
export const getMCells = async (
  mCellFilter?: MCellFilter,
): Promise<Array<MCell>> => {
  const response = await axiosInstance.get<Array<MCell>>(
    '/api/ManufacturingLocation/mCell',
    {
      params: {
        includeRollup: mCellFilter?.includeRollup,
        includeComponentMCells: mCellFilter?.includeComponentMCells,
        mCellId: mCellFilter?.mCellId,
        inventoryCenterId: mCellFilter?.inventoryCenterId,
        mCellCollectionId: mCellFilter?.mCellCollectionId,
      },
    },
  );
  return response.data;
};

/**
 * API call to get MCell by id
 * @param mCellId
 */
export const getMCellById = async (mCellId: string): Promise<MCell> => {
  const response = await axiosInstance.get<MCell>(
    `/api/ManufacturingLocation/mCell/${mCellId}`,
  );
  return response.data;
};

/**
 * API call to create MCell
 * @param mCellInfo
 */
export const createMCell = async (mCellInfo: MCellInfo): Promise<MCell> => {
  const response = await axiosInstance.post<MCell>(
    '/api/ManufacturingLocation/mCell',
    mCellInfo,
  );
  return response.data;
};

/**
 * API call to update MCell
 * @param params
 */
export const updateMCell = async (params: {
  id: string;
  info: MCellInfo;
}): Promise<MCell> => {
  const response = await axiosInstance.post<MCell>(
    `/api/ManufacturingLocation/mCell/${params.id}`,
    params.info,
  );
  return response.data;
};

/**
 * API call to delete MCell by id
 * @param mCellId
 */
export const deleteMCell = async (mCellId: string): Promise<string> => {
  const response = await axiosInstance.delete(
    `/api/ManufacturingLocation/mCell/${mCellId}`,
  );
  return response.data;
};

/**
 * API call to get MCell Collections
 */
export const getMCellCollections = async (): Promise<
  Array<MCellCollection>
> => {
  const response = await axiosInstance.get<Array<MCellCollection>>(
    '/api/ManufacturingLocation/mCellCollection',
  );
  return response.data;
};

/**
 * API call to get other user's private MCell Collections
 */
export const getOtherUsersPrivateMCellCollections = async (): Promise<
  Array<MCellCollection>
> => {
  const response = await axiosInstance.get<Array<MCellCollection>>(
    '/api/ManufacturingLocation/otherUsersPrivateMCellCollection',
  );
  return response.data;
};

/**
 * API call to create a new MCell Collection
 * @param mCellCollectionCreate
 */
export const createMCellCollection = async (
  mCellCollectionCreate: MCellCollectionCreateType,
): Promise<MCellCollection> => {
  const response = await axiosInstance.post<MCellCollection>(
    '/api/ManufacturingLocation/mCellCollection',
    mCellCollectionCreate,
  );
  return response.data;
};

/**
 * API call to create a new global MCell Collection
 * @param mCellCollectionCreate
 */
export const createMCellCollectionGlobal = async (
  mCellCollectionCreate: MCellCollectionCreateType,
): Promise<MCellCollection> => {
  const response = await axiosInstance.post<MCellCollection>(
    '/api/ManufacturingLocation/mCellCollectionGlobal',
    mCellCollectionCreate,
  );
  return response.data;
};

/**
 * API call to set an MCell Collection to global
 * @param mCellCollectionId
 */
export const setMCellCollectionGlobal = async (
  mCellCollectionId: string,
): Promise<MCellCollection> => {
  const response = await axiosInstance.post<MCellCollection>(
    `/api/ManufacturingLocation/mCellCollection/${mCellCollectionId}/setGlobal`,
  );
  return response.data;
};

/**
 * API call to set an MCell Collection to private
 * @param mCellCollectionId
 */
export const setMCellCollectionPrivate = async (
  mCellCollectionId: string,
): Promise<MCellCollection> => {
  const response = await axiosInstance.post<MCellCollection>(
    `/api/ManufacturingLocation/mCellCollection/${mCellCollectionId}/setPrivate`,
  );
  return response.data;
};

/**
 * API call to set MCells on an MCell Collection overriding previously set ones.
 */
export const setMCellsOnMCellCollection = async (param: {
  mCellCollectionId: string;
  mCellIds: Array<string>;
}): Promise<MCellCollection> => {
  const response = await axiosInstance.post<MCellCollection>(
    `/api/ManufacturingLocation/mCellCollection/${param.mCellCollectionId}/setMCells`,
    param.mCellIds,
  );
  return response.data;
};

/**
 * API call to add MCells to MCell collection ignoring duplicates
 * @param mCellCollectionId
 * @param mCellIds
 */
export const addMCellsToMCellCollection = async (
  mCellCollectionId: string,
  mCellIds: Array<string>,
): Promise<MCellCollection> => {
  const response = await axiosInstance.post<MCellCollection>(
    `/api/ManufacturingLocation/mCellCollection/${mCellCollectionId}/addMCells`,
    mCellIds,
  );
  return response.data;
};

/**
 * API call to remove MCells from MCell Collection
 * @param mCellCollectionId
 * @param mCellIds
 */
export const removeMCellsToMCellCollection = async (
  mCellCollectionId: string,
  mCellIds: Array<string>,
): Promise<MCellCollection> => {
  const response = await axiosInstance.post<MCellCollection>(
    `/api/ManufacturingLocation/mCellCollection/${mCellCollectionId}/removeMCells`,
    mCellIds,
  );
  return response.data;
};

/**
 * API call to rename an MCell Collection
 */
export const renameMCellCollection = async (param: {
  mCellCollectionId: string;
  newName: string;
}): Promise<MCellCollection> => {
  const response = await axiosInstance.post<MCellCollection>(
    `/api/ManufacturingLocation/mCellCollection/${param.mCellCollectionId}/rename`,
    JSON.stringify(param.newName),
  );
  return response.data;
};

/**
 * API call to copy a global MCell Collection to a new private MCell Collection
 * @param mCellCollectionId
 */
export const copyMCellCollectionToPrivate = async (
  mCellCollectionId: string,
) => {
  const response = await axiosInstance.post(
    `/api/ManufacturingLocation/mCellCollection/${mCellCollectionId}/copyToPrivate`,
  );
  return response.data;
};

/**
 * API call to delete an MCell Collection
 * @param mCellCollectionId
 */
export const deleteMCellCollection = async (mCellCollectionId: string) => {
  await axiosInstance.delete(
    `/api/ManufacturingLocation/mCellCollection/${mCellCollectionId}`,
  );
};

/**
 * API call to get an MCell Collection by id
 * @param mCellCollectionId
 */
export const getMCellCollectionById = async (
  mCellCollectionId: string,
): Promise<MCellCollection> => {
  const response = await axiosInstance.get<MCellCollection>(
    `/api/ManufacturingLocation/mCellCollection/${mCellCollectionId}`,
  );
  return response.data;
};

/**
 * API call to get Key MCells
 * @param inventoryCenterId
 */
export const getKeyMCells = async (
  inventoryCenterId: string,
): Promise<KeyMCell[]> => {
  const response = await axiosInstance.get(
    `/api/ManufacturingLocation/keyMCellInfo`,
    { params: { inventoryCenterId } },
  );
  return response.data;
};

/**
 * API call to update Key MCell
 * @param keyMCellUpdate
 */
export const updateKeyMCell = async (
  keyMCellUpdate: KeyMCellUpdate,
): Promise<KeyMCell> => {
  const response = await axiosInstance.post(
    `/api/ManufacturingLocation/keyMCellInfo`,
    keyMCellUpdate,
  );
  return response.data;
};

/**
 * API call to get Notification System
 * @param inventoryCenterId
 */
export const getNotificationSystem = async (
  inventoryCenterId: string,
): Promise<ManufacturingLocationNotificationSystem | undefined> => {
  const response = await axiosInstance.get(
    `/api/ManufacturingLocation/notificationSystem`,
    { params: { inventoryCenterId } },
  );
  return response.data;
};

/**
 * API call to set Notification System
 * @param notificationSystemInfo
 */
export const setNotificationSystem = async (
  notificationSystemInfo: ManufacturingLocationNotificationSystemInfo,
): Promise<ManufacturingLocationNotificationSystem> => {
  const response = await axiosInstance.post(
    `/api/ManufacturingLocation/notificationSystem`,
    notificationSystemInfo,
  );
  return response.data;
};

/**
 * API call to get inventory center ids associated with manufacturing location
 * @param manufacturingLocation
 */
export const getInventoryCenterIdsByManufacturingLocation = async (
  manufacturingLocation: ManufacturingLocation,
): Promise<Array<string>> => {
  const response = await axiosInstance.get<Array<string>>(
    `/api/ManufacturingLocation/inventoryCenterIdsByManufacturingLocation`,
    { params: manufacturingLocation },
  );
  return response.data;
};

/**
 * API call to get a single MCellCompletionConfig by Id
 * @param id
 */
export const getMCellCompletionConfigById = async (
  id: string,
): Promise<MCellCompletionConfigDto> => {
  const response = await axiosInstance.get<MCellCompletionConfigDto>(
    `/api/ManufacturingLocation/mCellCompletionConfig/${id}`,
  );
  return response.data;
};

/**
 * API call to get a list of MCellCompletionConfigs by MCell Id
 * @param mCellId
 */
export const getMCellCompletionConfigsByMCellId = async (
  mCellId: string,
): Promise<MCellCompletionConfigDto[]> => {
  const response = await axiosInstance.get<MCellCompletionConfigDto[]>(
    `/api/ManufacturingLocation/mCellCompletionConfig/mCell/${mCellId}`,
  );
  return response.data;
};

/**
 * API call to create a new MCellCompletionConfig
 * @param configCreate
 */
export const createMCellCompletionConfig = async (
  configCreate: MCellCompletionConfigCreate,
): Promise<MCellCompletionConfigDto> => {
  const response = await axiosInstance.post<MCellCompletionConfigDto>(
    `/api/ManufacturingLocation/mCellCompletionConfig`,
    configCreate,
  );
  return response.data;
};

/**
 * API call to update an MCellCompletionConfig
 * @param param
 */
export const updateMCellCompletionConfig = async (param: {
  id: string;
  configUpdate: MCellCompletionConfigUpdate;
}): Promise<MCellCompletionConfigDto> => {
  const response = await axiosInstance.post<MCellCompletionConfigDto>(
    `/api/ManufacturingLocation/mCellCompletionConfig/${param.id}`,
    param.configUpdate,
  );
  return response.data;
};

/**
 * API call to delete an MCellCompletionConfig
 * @param id
 */
export const deleteMCellCompletionConfig = async (
  id: string,
): Promise<void> => {
  await axiosInstance.delete(
    `/api/ManufacturingLocation/mCellCompletionConfig/${id}`,
  );
};

/**
 * API call to get Producing Location select options
 * @param inventoryCenterId
 */
export const getProducingLocationOptions = async (
  inventoryCenterId: string,
): Promise<OptionValue<string>[]> => {
  const response = await axiosInstance.get<Array<OptionValue<string>>>(
    `/api/ManufacturingLocation/producingLocation/selectOptions`,
    { params: { inventoryCenterId } },
  );
  return response.data;
};

/**
 * API call to get Production Line select options by Producing Location Id
 * @param producingLocationId
 */
export const getProductionLineOptions = async (
  producingLocationId: string,
): Promise<OptionValue<string>[]> => {
  const response = await axiosInstance.get<Array<OptionValue<string>>>(
    `/api/ManufacturingLocation/productionLine/selectOptions`,
    { params: { producingLocationId } },
  );
  return response.data;
};

/**
 * API call to get Work Center select options by Producing Location Id
 * @param producingLocationId
 */
export const getWorkCenterOptions = async (
  producingLocationId: string,
): Promise<OptionValue<string>[]> => {
  const response = await axiosInstance.get<Array<OptionValue<string>>>(
    `/api/ManufacturingLocation/workCenter/selectOptions`,
    { params: { producingLocationId } },
  );
  return response.data;
};

/**
 * API call to get Work Center Station select options by Work Center Id
 * @param workCenterId
 */
export const getWorkCenterStationOptions = async (
  workCenterId: string,
): Promise<OptionValue<string>[]> => {
  const response = await axiosInstance.get<Array<OptionValue<string>>>(
    `/api/ManufacturingLocation/workCenterStation/selectOptions`,
    { params: { workCenterId } },
  );
  return response.data;
};
